import { Container } from "../styleCompanent/Login";
import {useState} from 'react';
import Loading from "./sections/Loading";
import Axios from "../utils/httpClient";
import { setToken, issetToken } from "../utils/tokenStorge";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import InputMask from 'react-input-mask';
import { useEffect } from "react";

const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        if(issetToken()) navigate('/users')
    },[]);
    const postLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        let t = true, errr={};
        if(!user.login){
            errr = {...errr, login: true};
            t = false;
        }
        if(!user.password){           
            errr = {...errr, password: true};
            t = false;
        }
        if(t){
            const formdata = new FormData();
            const p = user?.login.split(' ').join('').split('_').join('').split('(').join('').split(')').join('').split('-').join('').split('+').join('');
            formdata.append("login", p);
            formdata.append("password", user.password);
            Axios().post('api/v1/login-operator', formdata).then(res=>{
                if(res.status===200){
                    setToken(get(res, 'data.token', ''));
                    navigate('/users');
                }else{
                    setErr({...err, common: true});
                }
                setLoading(false);
            }).catch(err=>{
                setLoading(false);
                setErr({...err, common: true});
            })
        }else{
            setLoading(false);
            setErr(errr);
        }
    }
    return(<Container>
        <div>
            <form onSubmit={postLogin} >
                <div className="inputTarget" style={{textAlign: 'center'}}>
                    <img src='/logo.svg' alt=''/>
                </div>
                <div className="inputTarget">
                    <label>Login</label>
                    <InputMask  mask='+\9\98(99) 999-99-99' alwaysShowMask={true} value={user.login} name='login'
                        onChange={e=>{setUser({...user, login: e.target.value}); setErr({...err, login: false, common: false});}}
                    />
                    {err.login?<div style={{color: 'red'}}>Loginni kiriting</div>:''}
                </div>
                <div className="inputTarget">
                    <label>Password</label>
                    <input type='password' name='password'  value={user.password} 
                        onChange={e=>{setUser({...user, password: e.target.value}); setErr({...err, login: false, common: false});}}
                    />
                    {err.password?<div style={{color: 'red'}}>Parolni kiriting</div>:''}
                    {err.common?<div style={{color: 'red'}}>Login yoki parol xato!</div>:''}
                </div>
                <div className="inputTarget">
                    {loading?<Loading/>:<input type='submit' name='submit' value="Login" className="submit"/>}
                </div>
            </form>
        </div>
    </Container>);
}
export default Login;