import { get } from "lodash";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Container } from "../styleCompanent/Home";
import Axios from "../utils/httpClient";
import Layout from "./sections/Layout";
import Loading from "./sections/Loading";
import {BiSearchAlt} from 'react-icons/bi';

const Home = () => {
    const [users,  setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingM, setLoadingM] = useState(false);
    useEffect(()=>{
        getUsers();
    }, []);
    const getUsers = (next='') => {
        if(next) setLoadingM(true);
        else setLoading(true);
        Axios().get(next?next:'api/v1/users/')
        .then(res=>{
            setUser({...get(res, 'data', {}), results: [...get(users, 'results', []), ...get(res, 'data.results', [])]});
            setLoading(false);
            setLoadingM(false);
        })
        .catch(err=>{
            setLoading(false);
            setLoadingM(false);
        })
    }
    const submitObj = e => {
        e.preventDefault();
        const data = new FormData(e.target);
        setLoading(true);
        Axios().get('api/v1/users/'+(data.get('search')?'?search='+data.get('search'):''))
        .then(res=>{
            setUser({...get(res, 'data', {}), results: get(res, 'data.results', [])});
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
        })
    }
    return(<Layout>
        <Container>
            <div>
                <form onSubmit={submitObj}>
                    <div className="search_target">
                        <input type='text' placeholder="Search..." name='search'/>
                        <button type="submit" className="btn" ><BiSearchAlt/></button>
                    </div>
                </form>
            </div>
            {loading?<Loading/>:<>
            <table>
                <tr>
                    <th scope="col">User id</th>
                    <th scope="col">Username</th>
                    <th scope="col">FISh</th>
                    <th scope="col">Create at</th>
                    <th scope="col">Last login</th>
                    <th scope="col">Postname</th>
                    <th scope="col">Complate</th>
                </tr>
                {get(users, 'results', []).map((item, index)=>(
                    <tr key={get(item, 'id', index)}>
                        <td><Link to={'/users/'+get(item, 'id', 0)}>#{get(item, 'id', '')}</Link></td>
                        <td>{get(item, 'username', '-')}</td>
                        <td>{get(item, 'first_name', '')} {get(item, 'last_name', '')}</td>
                        <td><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'created_at', '')}</Moment></td>
                        <td><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'last_login', '')}</Moment></td>
                        <td>{get(item, 'postname', '-')}</td>
                        <td>{get(item, 'complete', 0)===1?'Successful':get(item, 'complete', 0)===2?'Sms Accept':"Sign Up"}</td>
                    </tr>
                ))}
            </table>
            {get(users, 'next', false)?
            <div className="more">
                {loadingM?<Loading/>:<button className="btn" onClick={()=>getUsers(get(users, 'next', ''))}>More users</button>}
            </div>:''}</>}
        </Container>
    </Layout>);
}
export default Home;