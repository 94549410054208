import _ from 'lodash';

const INITIAL_STATE = {};

const reducers = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        default:
			return state;
	}
};
export default reducers;