import styled from 'styled-components';

export const Container = styled.div`
    overflow-x: auto;
    margin-bottom: 10px;
    & .search_target{
        display: flex;
        margin: 10px 0;
        & button{
            background: var(--blue);
            border-color: var(--blue);
            font-size: 18px;
            color: var(--white);
            border-radius: 5px;
            width: 40px;
            margin-left: 10px;
        }
        & input{
            padding: 0 10px;
        }
    }
    & table{
        border-collapse: collapse;
        width: 100%;
        & td, th {
            border: 0;
            text-align: left;
            padding: 8px;
            white-space: nowrap;
        }

        & tr:nth-child(even) {
            background-color: var(--light-brown);
        }
    }
    & .more{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    }
`;