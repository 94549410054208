import { Link, useNavigate } from "react-router-dom";
import { Container } from "../../styleCompanent/sections/Header";
import {AiOutlineLogout} from 'react-icons/ai';
import Rodal from 'rodal';
import { useState } from "react";
import { removeToken } from "../../utils/tokenStorge";

const Header = () => {
    const [rodal, setRodal] = useState(false);
    const navigate = useNavigate();
    const logOut = () => {
        setRodal(true);
        removeToken();
        navigate('/');
    }
    return(<>
        <Container>
            <Link to='/users'><img src='/logo.svg' alt=''/></Link>
            <button className="btn logout" onClick={()=>setRodal(true)}><AiOutlineLogout size={20}/></button>
            <Rodal visible={rodal} onClose={()=>setRodal(false)} className='radal_model'>
                <div className="target">
                    <h3>Tizimdan chiqish</h3>
                    <p>Rostdan ham tizimdan chiqmoqchisizmi?</p>
                    <button className="btn signout" onClick={()=>{logOut()}}><AiOutlineLogout size={20}/> Chiqish</button>
                </div>
            </Rodal>
        </Container>
        
    </>)
}
export default Header;