import styled from 'styled-components';

export const Container = styled.div`  

    & .promotion{
        & .edit{
            margin-left: 10px;
            padding: 5px 10px;
            background: var(--blue);
            color: var(--white);
            border-radius: 5px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            float: right;
        }
        & .info_target{
            padding-left: 20px;
            margin-bottom: 20px;
            & div{
                font-size: 12px;
            }
        }
        & .status{
            background: var(--toastify-color-success);
            color: #fff;
            padding: 2px 5px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            margin: 0 5px;
        }
        & .status_red{
            background: var(--red);
        }
    }
`;