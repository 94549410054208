import styled from 'styled-components';

export const LoadingStyle = styled.div`
    display: flex;
    justify-content: center;
    width: 100%; 
    position: relative; 
    overflow: hidden; 
    & .loader {
        border-radius: 50%;
        width: 6em;
        height: 6em;
        margin: 10px;
        font-size: 7px;
        position: absolute;
        top: 0px;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(38,202,172, 0.2);
        border-right: 1.1em solid rgba(38,202,172, 0.2);
        border-bottom: 1.1em solid rgba(38,202,172, 0.2);
        border-left: 1.1em solid red;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
        &:after {
            border-radius: 50%;
            width: 6em;
            height: 6em;
        }
        @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
        }
    }
`;