import { LoadingStyle } from "../../styleCompanent/sections/LoadingStyle";

const Loading = (props) =>{
   const {size, color} = props;
   const loadStyle = () => {
       let style = {};
       if(size) style = {...style, fontSize: size, margin: size};
       if(color) style = {...style, borderLeft: '1.1em solid '+color};
       return style;
   }
   return (
       <LoadingStyle style={{ padding: size?size*4:30 }}>     
           <div className="loader" style={loadStyle()}></div>
       </LoadingStyle>
   )
}
export default Loading;