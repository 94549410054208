import { get } from 'lodash';
import {useState} from 'react';
import {BiLayerPlus} from 'react-icons/bi';
import {Container} from '../../styleCompanent/sections/NewLog';
import Axios from '../../utils/httpClient';
import Loading from './Loading';

const NewLog = (props) => {
    const {user_id, setLogs, logs} =  props;
    const [add, setAdd] = useState(false);
    const [log, setLog] = useState({});
    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(false);
    const submitLog = (e) => {
        e.preventDefault();
        setLoading(true);
        let t = true, errr={};
        if(!log.desc){ errr = {...errr, desc: true}; t = false; }
        if(!log.time){ errr = {...errr, time: true};  t = false; }
        if(t){
            const formdata = new FormData();
            formdata.append("user", user_id);
            formdata.append("desc", log.desc);
            formdata.append("time", log.time);
            Axios().post('api/v1/operator/log/', formdata).then(res=>{
                if(res.status===201){
                    setLogs({...logs, results: [res.data, ...get(logs, 'results', [])]});
                    setAdd(false);
                    setLog({});
                }else{
                    setErr({...err, common: true});
                }
                setLoading(false);
            }).catch(err=>{
                setLoading(false);
                setErr({...err, common: true});
            })
        }else{
            setLoading(false);
            setErr(errr);
        }
    }
    return(<Container>
        {add?<form onSubmit={submitLog}>
            <div className="inputTarget">
                <label>Description</label>
                <input type='text' name='desc'  value={log.desc}  style={{width: '100%'}}
                    onChange={e=>{setLog({...log, desc: e.target.value}); setErr({...err, desc: false, common: false});}}
                />
                {err.desc?<div style={{color: 'red'}}>Description required</div>:''}
            </div>
            <div className="inputTarget">
                <label>Time</label>
                <input type='number' name='time'  value={log.time}   style={{width: 190}}
                    onChange={e=>{setLog({...log, time: e.target.value}); setErr({...err, time: false, common: false});}}
                />
                {err.time?<div style={{color: 'red'}}>Time required</div>:''}
            </div>
            <div className='btns'>
                {loading?<span className='btn'><Loading size={4}/></span>:<button className='btn' type='submit'>Save</button>}
                <button className='btn cancel' onClick={()=>{setAdd(false); setLog({})}} type='button'>Cancel</button>
            </div>
        </form>
        :<div className='add' onClick={()=>setAdd(true)}><BiLayerPlus/> New Log</div>}
    </Container>)
}
export default NewLog;