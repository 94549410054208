import Header from "./Header";

const Layout = (props) => {
    const {children} = props;
    return(<div className="container">
        <div className="container_target">
            <Header/>
            {children}
        </div>
    </div>)
}
export default Layout;