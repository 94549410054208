import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    @media (max-width: 580px) {
        flex-direction: column;
    }
    & .user_info{
        width: 300px;
        padding: 5px;
        @media (max-width: 730px) {
            width: 250px;
        }
        @media (max-width: 580px) {
            width: auto;
            & .card{
                display: flex;
                flex-wrap: wrap;
            }
            & .info{
                margin: 0 10px;
            }
        }
    }
    & .user_log{
        width: calc(100% - 300px);
        padding: 5px;
        @media (max-width: 730px) {
            width: calc(100% - 250px);
        }
        @media (max-width: 580px) {
            width: auto;
        }
        & .more{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    & .card{
        border-radius: 5px;
        background-color: var(--light-brown);
        /* min-height: 20vh; */
        padding: 10px;
        margin-bottom: 10px;
        & .user_img{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            & img{
                width: 50px;
                height: 50px;
                margin-right: 5px;
                border-radius: 50%;
            }
        }
        & .info{
            & .label{
                font-size: 12px;
            }
            & .user_data{
                font-size: 18px;
                margin: 0 0 10px 0;
            }
        }
        & .log_item{
            & .desc{
                font-weight: 500;
                margin-bottom: 10px;
            }
            & .item_label{
                font-size: 12px;
                padding-left: 10px;
            }
            & .item_info{
                margin-bottom: 10px;
                padding-left: 10px;
            }
        }
    }
    
`;