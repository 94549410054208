import styled from 'styled-components';

export const Container = styled.div`
    & .inputTarget{
        margin-bottom: 10px;
    }
    & .status{
        display: flex;
        align-items: center;
    }
    & .add{
        display: flex;
        align-items: center;
        color: #999;
        cursor: pointer;
        & svg{
            margin-right: 5px;
        }
    }
    & .btns{
        display: flex;
        margin-bottom: 10px;
        & .cancel{
            background-color: #999;
            border-color: #999;
            margin-left: 10px;
        }
    }
    & .del{
        margin-left: 10px;
        background: var(--red);
        border-color: var(--red);
    }
`;