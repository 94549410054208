import { Route, Routes } from "react-router-dom";
import Home from '../components/Home';
import Login from "../components/Login";
import UserView from "../components/UserView";

const Routers = () => {
    return(
        <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/users' element={<Home/>}/>
            <Route path='/users/:id' element={<UserView/>}/>
        </Routes>
    )
}
export default Routers;