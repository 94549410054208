import Layout from "./sections/Layout";
import { Container } from "../styleCompanent/UserView";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "../utils/httpClient";
import { get } from "lodash";
import Loading from "./sections/Loading";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import NewLog from "./sections/NewLog";
import Promotions from "./sections/Promotions";

const UserView = () => {
    const params = useParams();
    const [user,  setUser] = useState({});
    const [logs,  setLogs] = useState({});
    const [promotionList,  setPromotionList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingL, setLoadingL] = useState(true);
    const [loadingLM, setLoadingLM] = useState(true);
    useEffect(()=>{
        getUsers();
        getLogs();
        getPromotions();
    }, []);
    const getUsers = () => {
        setLoading(true);
        Axios().get(`api/v1/users/${params.id}/`)
        .then(res=>{
            setUser(get(res, 'data', {}));
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
        })
    }
    const getLogs = (next='') => {
        if(next) setLoadingLM(true);
        else setLoadingL(true);
        Axios().get(next?next:`api/v1/operator/log/?user=${params.id}`)
        .then(res=>{
            setLogs({...get(res, 'data', {}), results: [...get(logs, 'results', []), ...get(res, 'data.results', [])]});
            setLoadingLM(false);
            setLoadingL(false);
        })
        .catch(err=>{
            setLoadingLM(false);
            setLoadingL(false);
        })
    }
    const getPromotions = () => {
        Axios().get(`/api/v1/promotions/?per_page=100`)
        .then(res=>{
            const data = get(res, 'data.results', []);
            let l = [];
            data.forEach(item => {
                l.push({
                    value: get(item, 'id', 0),
                    label: get(item, 'name', 0)+' — '+get(item, 'sum', 0)+(get(item, 'is_percentage', 0)?"%":" So'm"),
                })
            });
            setPromotionList(l);
        });
    }
    return(<Layout>
        <Container>
            <div className="user_info">
                <div className="card">
                    {loading?<Loading/>:<>
                    <div className="user_img">
                        <img src={get(user, 'avatar', '')} alt=''/>
                        <div>
                            <div>{get(user, 'first_name', '******')}</div>
                            <div>{get(user, 'last_name', '****')}</div>
                        </div>
                    </div>
                    <div className="info">
                        <div className="label">User id</div>
                        <div className="user_data">{get(user, 'id', '')}</div>
                    </div>
                    <div className="info">
                        <div className="label">Username</div>
                        <div className="user_data">{get(user, 'username', '')}</div>
                    </div>
                    <div className="info">
                        <div className="label">Created at</div>
                        <div className="user_data"><Moment format='HH:mm DD-MM-YYYY'>{get(user, 'created_at', '')}</Moment></div>
                    </div>
                    <div className="info">
                        <div className="label">Last login</div>
                        <div className="user_data"><Moment format='HH:mm DD-MM-YYYY'>{get(user, 'last_login', '')}</Moment></div>
                    </div>
                    <div className="info">
                        <div className="label">Postname</div>
                        <div className="user_data">{get(user, 'postname', '****')}</div>
                    </div>
                    <div className="info">
                        <div className="label">Complate</div>
                        <div className="user_data">{get(user, 'complete', 0)===1?'Successful':get(user, 'complete', 0)===2?'Sms Accept':"Sign Up"}</div>
                    </div>
                    </>}
                </div>
            </div>
            <div className="user_log">
                {loadingL?<div className="card"><Loading/></div>:<>
                    <div className="card">
                        <NewLog user_id={params?.id} setLogs={v=>setLogs(v)} logs={logs}/>
                    </div>
                    {get(logs, 'results', []).map((item, index)=>(<div className="card">
                        <div key={index} className='log_item'>
                            <div className="desc">{index+1}. {get(item, 'desc', '******')}</div>
                            <div className="item_label">Operator #{get(item, 'operators.id', '******')}</div>
                            <div className="item_info">{get(item, 'operators.first_name', '')} {get(item, 'operators.last_name', '')} {get(item, 'operators.username', '')}</div>
                            <div className="item_label">Time to talk on the phone</div>
                            <div className="item_info">{get(item, 'time', '0')} minutes</div>
                            <div className="item_label">Create at</div>
                            <div className="item_info"><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'created_at', '')}</Moment></div>
                        </div>
                        <Promotions 
                            user_id={get(user, 'id', '')} 
                            log_id={get(item, 'id', 0)} 
                            promotions={get(item, 'promotions', [])} 
                            promotionList={promotionList}
                            setLogs={v=>setLogs(v)}
                            logs={logs}
                        />
                    </div>))}
                    {get(logs, 'next', false)?
                    <div className="more">
                        {loadingLM?<Loading/>:<button className="btn" onClick={()=>getLogs(get(logs, 'next', ''))}>More logs</button>}
                    </div>:''}
                </>}  
            </div>
        </Container>
    </Layout>)
}
export default UserView;