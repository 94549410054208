import { Provider } from 'react-redux';
import createStore from './utils/store';
import { BrowserRouter } from 'react-router-dom';
import Routers from './routers/Routers';
import WebSocketProvider from './utils/webSocketProvider';

const App = () => {
  const store = createStore();
  return (
    <Provider store={store}>
      <WebSocketProvider>
        <BrowserRouter>
          <Routers/>
        </BrowserRouter>
      </WebSocketProvider>
    </Provider>
  );
}
export default App;