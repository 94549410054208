import { Container } from "../../styleCompanent/sections/Promotions";
import {Container as FormContainer} from '../../styleCompanent/sections/NewLog';
import {BiLayerPlus, BiEditAlt} from 'react-icons/bi';
import {get} from 'lodash';
import Moment from "react-moment";
import Select from 'react-select';
import { useState } from 'react';
import Axios from "../../utils/httpClient";
import Loading from "./Loading";
import {IoMdCheckmarkCircleOutline, IoIosCloseCircleOutline} from 'react-icons/io';

const Promotions = (props) => {
    const {promotions=[], promotionList, log_id, user_id, setLogs, logs} = props;
    const [add, setAdd] = useState(0);
    const [obj, setObj] = useState({});
    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(false);
    const submitObj = (e) => {
        e.preventDefault();
        setLoading(true);
        let t = true, errr={};
        if(!obj?.promotion?.value){ errr = {...errr, promotion: true}; t = false; }
        if(!obj.end_date){ errr = {...errr, end_date: true};  t = false; }
        if(t){
            const formdata = new FormData();
            formdata.append("end_date", obj.end_date);
            formdata.append("status", obj.status);
            formdata.append("log", log_id);
            formdata.append("user", user_id);
            formdata.append("promotion", obj.promotion.value);
            AxiosFunk(formdata, obj?.id).then(res=>{
                if(res.status===201 || res.status===200){
                    let l = [], p=[], is_add=true;
                    get(logs, 'results', []).forEach(item => {
                        if(log_id===item.id){
                            get(item, 'promotions', []).forEach(pro => {
                                if(add===pro.id){
                                    p.push(res?.data);
                                    is_add=false;
                                }else p.push(pro);
                            });
                            if(is_add) p.push(res?.data);
                            l.push({...item, promotions: p});
                        }else l.push(item);
                    });
                    // console.log('==>', {...logs, results: l});
                    setLogs({...logs, results: l});
                    setAdd(0);
                    setObj({});
                }else{
                    setErr({...err, common: true});
                }
                setLoading(false);
            }).catch(err=>{
                setLoading(false);
                setErr({...err, common: true});
            })
        }else{
            setLoading(false);
            setErr(errr);
        }
    }
    const AxiosFunk = (data, id=0) =>{
        if(id) return Axios().put(`api/v1/operator/promotion/${id}/`, data);
        return Axios().post('api/v1/operator/promotion/', data);
    }
    const delObj = () => {
        setLoading(true);
        Axios().delete(`api/v1/operator/promotion/${add}/`).then(res=>{
            if(res.status===204){
                let l = [], p=[], is_add=true;
                get(logs, 'results', []).forEach(item => {
                    if(log_id===item.id){
                        get(item, 'promotions', []).forEach(pro => {
                            if(add!==pro.id) p.push(pro);
                        });
                        if(is_add) p.push(res?.data);
                        l.push({...item, promotions: p});
                    }else l.push(item);
                });
                setLogs({...logs, results: l});
                setAdd(0);
                setObj({});
            }else{
                setErr({...err, common: true});
            }
            setLoading(false);
        }).catch(err=>{
            setLoading(false);
            setErr({...err, common: true});
        });
    }
    return(<Container>
        <h4>Promotions</h4>
        {promotions.map((item, index)=>(<>
            {add===item.id?<FormContainer>
            
            <form onSubmit={submitObj}>
                <h5>{index+1}. Edit Promotion</h5>
                <div className="inputTarget">
                    <label>Discounts</label>
                    <Select
                        value={obj.promotion}
                        options={promotionList}
                        onChange={v=>{setObj({...obj, promotion: v}); setErr({...err, promotion: false, common: false});}}
                    />
                    {err.promotion?<div style={{color: 'red'}}>Discount required</div>:''}
                </div>
                <div className="inputTarget">
                    <label>End Date</label>
                    <input type='datetime-local' name='end_date'  value={obj?.end_date?.substring(0,16)}   style={{width: 190}}
                        onChange={e=>{setObj({...obj, end_date: e.target.value}); setErr({...err, end_date: false, common: false});}}
                    />
                    {err.end_date?<div style={{color: 'red'}}>End Date required</div>:''}
                </div>
                <div className="inputTarget">
                    <label className="status">
                    <input type='checkbox' name='status'  checked={obj.status}   style={{width: 20}}
                        onChange={e=>{setObj({...obj, status: !obj.status});}}
                    /> Status</label>
                </div>
                <div className='btns'>
                    {loading?<span className='btn'><Loading size={4}/></span>:<button className='btn' type='submit'>Save</button>}
                    {loading?<span className='btn del'><Loading size={4}/></span>:<button className='btn del' onClick={()=>delObj()}>Delete</button>}
                    <button className='btn cancel' onClick={()=>{setAdd(0); setObj({})}} type='button'>Cancel</button>
                </div>
            </form>
            </FormContainer>:<div key={index} className='promotion'>
                <div>
                    {index+1}. 
                    {get(item, 'status', false)?<span className="status"><IoMdCheckmarkCircleOutline/></span>
                    :<span className="status status_red" ><IoIosCloseCircleOutline/></span>}
                    <span className="info">{get(item, 'promotions.name', '*****')} —</span>
                    <span className="info">
                        {get(item, 'promotions.sum', 0)}
                        {get(item, 'promotions.is_percentage', false)?'%':" So'm"}
                    </span>
                    <span className="edit"
                        onClick={()=>{setAdd(item.id); setObj({
                            id: get(item, 'id', 0),
                            promotion: {
                                value: get(item, 'promotions.id', 0), 
                                label: `${get(item, 'promotions.name', '*****')} — ${get(item, 'promotions.sum', 0)}${get(item, 'promotions.is_percentage', false)?'%':" So'm"}`},
                            end_date: get(item, 'end_date', ''),
                            status: get(item, 'status', false)
                        })}}
                    >
                        <BiEditAlt/>
                    </span>
                    <div className="info_target">
                        <div>Created at</div>
                        <span className="info"><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'created_at', '')}</Moment></span>
                        <div>Updated at</div>
                        <span className="info"><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'updated_at', '')}</Moment></span>
                        <div><b>End date at</b></div>
                        <span className="info"><Moment format='HH:mm DD-MM-YYYY'>{get(item, 'end_date', '')}</Moment></span>
                    </div>
                </div>
            </div>}
        </>))}
        <FormContainer>
        {add===-1?<form onSubmit={submitObj}>
            <h5>Add Promotion</h5>
            <div className="inputTarget">
                <label>Discounts</label>
                <Select
                    value={obj.promotion}
                    options={promotionList}
                    onChange={v=>{setObj({...obj, promotion: v}); setErr({...err, promotion: false, common: false});}}
                />
                {err.promotion?<div style={{color: 'red'}}>Discount required</div>:''}
            </div>
            <div className="inputTarget">
                <label>End Date</label>
                <input type='datetime-local' name='end_date'  value={obj.end_date}   style={{width: 190}}
                    onChange={e=>{setObj({...obj, end_date: e.target.value}); setErr({...err, end_date: false, common: false});}}
                />
                {err.end_date?<div style={{color: 'red'}}>End Date required</div>:''}
            </div>
            <div className="inputTarget">
                <label className="status">
                <input type='checkbox' name='status'  checked={obj.status}   style={{width: 20}}
                    onChange={e=>{setObj({...obj, status: !obj.status});}}
                /> Status</label>
            </div>
            <div className='btns'>
                {loading?<span className='btn'><Loading size={4}/></span>:<button className='btn' type='submit'>Save</button>}
                <button className='btn cancel' onClick={()=>{setAdd(0); setObj({})}} type='button'>Cancel</button>
            </div>
        </form>
        :<div className='add' onClick={()=>setAdd(-1)}><BiLayerPlus/> New Promotion</div>}
        </FormContainer>
    </Container>);
}
export default Promotions