import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    & .inputTarget{
        margin-bottom: 10px;
        width: 250px;
        & input{
            width: 100%;
        }
        & .submit{
            text-align: center;
            background: var(--blue);
            color: #fff;
            border-color: var(--blue);
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        }
    }
`;