import styled from 'styled-components';

export const Container = styled.div`
    border-bottom: 1px solid #ccc;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .logout, .signout{
        width: 45px;
        background: var(--red);
        border-color: var(--red);
    }
    & .signout{
        width: 110px;
        & svg{
            margin-right: 10px;
        }
    }
    & .target{
        display: flex;
        flex-direction: column;
        align-items: center;
        & h3{
            margin: 0;
        }
    }
    & .rodal-dialog{
        height: max-content !important;
    }
`;